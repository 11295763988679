
/* You can add global styles to this file, and also import other style files */
$facebook-color: #0b72f7;
$branding-primary: #fe5758;
$branding-secondary: #23d2ad;
$link-color: #333;

$verified-color: #23a8d3;

$lg-breakpoint: 992px;

.custom-modal {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.uppercase {
    text-transform: uppercase;
}

.form-col {
    padding: 50px;
}

@media (max-width: $lg-breakpoint) {
    /* CSS that should be displayed if width is equal to or less than 800px goes here */
    .form-col {
        padding: 30px 10px;
    }
}

.mt-20 {
    margin-top: 20px;
}

.color-branding-primary {
    color: $branding-primary;
}

.btn-tpb-primary {
    background: $branding-primary;
    color: #fff;
}

.btn-tpb-secondary {
    background: $branding-secondary;
    color: #fff;
}


.verified-icon-sm {
    color: $verified-color;
    margin-left: .5rem;
}


.link,
.link:active,
.link:visited {
    color: $link-color;
    text-decoration: none;
    color: $branding-primary;
}

.link:hover {
    cursor: pointer;
}



.dnd-container {
    background :$branding-secondary;
    margin-top: 5rem;
    position: relative;
    border-radius: 13px;
    border: 2px solid #eee;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    
    padding-top: 0;

    .back-screen {
        border-radius: 13px;
        background: rgba(#fff, 0.85);
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2.5rem;
    }

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -2;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .circle-container {
        position: relative;
        top: -50px;
    }

    .circle {
        z-index: 10;
        color: #fff;
        background: $branding-secondary;
        width: 3rem;
        height: 3rem;
        border-radius: 100%;
        position: relative;
        display: block;
        margin: 0 auto;
        margin-top: .5rem;
        font-weight: bolder;
    }

    .circle-label {
        z-index: 10;
        font-size: 1rem;
        text-transform: uppercase;
    }

    .circle-number {
        z-index: 10;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .row-circle {
        position: relative;
        top: -300px;
    }

    .upload-icon {
        color:$branding-secondary;
        font-size: 3rem;
    }

}

.form-check-input:checked {
    background-color: $branding-primary !important;
    border-color: $branding-primary !important;
}

.btn-tpb-outline-secondary {
    color: $branding-secondary;
    border-color: $branding-secondary;
    background: transparent;
}

.btn-tpb-outline-primary {
    color: $branding-primary;
    border-color: $branding-primary;
    background: transparent;
}

.form-label-valid {
    color: green;
}

.form-label-invalid {
    color: red;
}

.form-error-msg {
    font-size: .75rem;
    color: red;
}

.dropdown-item:active {
    background: $branding-primary;
}

.page-item.active .page-link {
    background-color: $branding-primary;
    border-color: $branding-primary;
}

.page-link {
    color: $branding-primary;
}

.page-link:hover {
    cursor: pointer;
}

.verified {
    color: $verified-color;
}


/* ###### Loading ###### */
.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: $branding-primary;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}
@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }
    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}
